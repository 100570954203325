.ad-container{
    display: grid;
    padding: var(--section-padding);
    grid-template-columns: auto;
    grid-gap: 0rem;
}

.ad-item{
    width: 100%;
    /* height: 8rem; */
    padding: 0.5rem;
    box-sizing: border-box;
    background: var(--border-color-2);
    box-shadow:
    0px 3.4px 2.7px rgba(0, 0, 0, 0.022),
    0px 8.7px 6.9px rgba(0, 0, 0, 0.031),
    0px 17.7px 14.2px rgba(0, 0, 0, 0.039),
    0px 36.5px 29.2px rgba(0, 0, 0, 0.048),
    0px 100px 80px rgba(0, 0, 0, 0.07);
    position: relative;
    /* animation: mymove 40s infinite; */
}
/* @keyframes mymove {
    0% {top: 0;}
    50% {top: -20rem;}
    100% {top: 0;}
  } */
.ad-img-1{
    width: 100%;
    height: 8rem;
    object-fit: cover;
    border-radius: 0.4rem;
}

.ad-img-2{
    width: 50%;
    height: 6rem;
    object-fit: cover;
    border-radius: 0.4rem;
}

.sponsored{
    /* font-size: 1.3rem; */
    margin: 0.1rem 0;
    color: var(--color3);
    text-align: center;
}
.desc{
    width: 100%;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    color: var(--color3);
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    background-color: var(--preview-background);
}

.sm-items{
    box-shadow:
    0px 3.4px 2.7px rgba(0, 0, 0, 0.022),
    0px 8.7px 6.9px rgba(0, 0, 0, 0.031),
    0px 17.7px 14.2px rgba(0, 0, 0, 0.039),
    0px 36.5px 29.2px rgba(0, 0, 0, 0.048),
    0px 100px 80px rgba(0, 0, 0, 0.07);
}

.ad-item-inner{
    position: relative;
}

.ad-img-1-after{
    position: absolute; 
    top: 0;
    left : 0;
    width: 100%;
    height: 100%;
    border-radius : 6px;
    background: linear-gradient(to top, rgba(40, 41, 56, 0.733),rgba(242, 244, 253, 0.15), rgba(242, 244, 253, 0.15), rgba(242, 244, 253, 0.15))
}


@media screen and (max-width: 960px){
    .ad-container{
        display: none;   
    }
}