.signup{
    display: flex;
    padding: var(--page-padding);
    height: 100vh;
}
.signup-left, .signup-right{
    display: grid;
    padding-top: var(--page-padding);
    align-items: center;
    justify-content: center;
    height: 100%
}
.signup-right{
    /* background: var(--background-color-9); */
    background: #243eb34d;
    border-radius: 1rem;
    box-shadow:
    0.6px 2.8px 2.2px rgba(0, 0, 0, 0.056),
    1.5px 6.7px 5.3px rgba(0, 0, 0, 0.081),
    2.8px 12.5px 10px rgba(0, 0, 0, 0.1),
    4.9px 22.3px 17.9px rgba(0, 0, 0, 0.119),
    9.2px 41.8px 33.4px rgba(0, 0, 0, 0.144),
    22px 100px 80px rgba(0, 0, 0, 0.2);
}
.input{
    display: block;
    margin: var(--input-margin);
    padding: var(--input-padding2);
    width: auto;
    border-radius: var(--section-border-radius);
    color: var(--color-7);
    font-size: 1.2rem;
    font-weight: 400;
}
.input:focus{
    outline: none;
}
.title{
    font-size: var(--page-title1);
    font-weight: 200;
    letter-spacing: 0.8rem;
    text-align: center;
    color: var(--color2);
}
.btn{
    float:right;
}
.page-title{
    font-size: var(--page-title2);
    text-align: center;
    color: var(--color3);
    font-weight: 200;
    letter-spacing: 0.8rem;
}
.signup{
    font-size: var(--small-font1);
}

.errorNotice{
    display: flex;
    position: absolute;
    border: var(--error-border);
    font-size: var(--error-font-size);
    top: 40%;
    width: var(--error-width);
    height: var(--error-height);
    background: var(--error-background);
    color: var(--error-color);
    box-shadow: var(--error-shadow);
    text-align: center;
    padding: var(--error-padding);
    justify-content: center;
    align-items: center;
}
.auth-btns{
    margin-top: 1rem;
}
.auth-signup-btn{
    cursor: pointer;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-8);
    text-decoration: none;
    border: 1px solid none;
    opacity: 0.9;
    color: var(--color-auth-alt);
}
.auth-alt{
    color: var(--color-8);
    opacity: 1rem;
}
.auth-signup-btn1{
    cursor: pointer;
    float : left;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color3);
    text-decoration: none;
    border: 1px solid none;
    padding: var(--btn-padding);
    border-radius: var(--btn-border-radius);

}
.auth-signup-btn2{
    cursor: pointer;
    float : right;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color3);
    text-decoration: none;
    border: 1px solid var(--color3);
    border: 1px solid none;
    padding: var(--btn-padding);
    border-radius: var(--btn-border-radius);
}
@media screen and (max-width : 600px){
    .signup-left{
        height: auto;
    }
    .signup-right{
        height: 80vh;
    }
    .input{
        font-size: 1.03rem;
        font-weight: 500;
        padding: var(--input-padding3);
    }
    .title{
        font-size: 1.7rem;
    }
}
