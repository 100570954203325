.sidebarContainer1{
    width: 18rem; 
    height: 100vh;
    position: fixed;
    top:0;
    left : 0;
    z-index: 12;
    transform : translateX(-100%);
    transition: all 0.7s;
    background: var(--background-color);
}
.sidebarContainer2{
    width: 18rem;
    height: 100vh;
    position: fixed;
    top:0;
    left : 0;
    z-index: 12;
    transform : translateX(0);
    transition: all 0.7s;
    background: var(--background-color);
    display: none;
}
.sidebarTop {
    height:  4rem;
    display: flex;
    place-items: center;
}
.close-icon{
    float : right;
    position: absolute;
    right : 1rem;
    top:1rem;
    color: var(--color-close);
    width : var(--icon-size1);
}

.sidebarlogo{
    width: 100%;
    font-size: var(--logo-font-size);
    text-align: center;
}

.top-inner{
    display: flex; 
    justify-content: space-around;
    background: #fff;
    border-radius: 2rem;
    padding: 0.8rem;
    margin-left: 1rem; 
    background: var(--background-color);
}

/* .search{
    border: none;
    margin-left: 1rem; 
    background: var(--background-color);
}
.search:focus{
    outline: none;
} */

.sideTop{
    padding : 0.3rem 1rem
}
.sideTop-2{
    padding : 0.3rem 1.4rem
}
.sideTop-ul{
    list-style-type: none;
    color:var(--color2)
}
.sideTop-li{
    /* margin: 0.8rem 0;
    font-size: 0.9rem;
    color: var(--color); */
    cursor: pointer;
    text-decoration: none;
    color:var(--color);
    margin: 0.8rem 0;
}
.sideTop-li-inner{
    /* text-decoration: none;
    color:blue */

    /* font-size: 0.9rem;*/
    text-decoration: none; 
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0;
    padding-left: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    background: var(--background-color);
    color:var(--color);
}
.sideTop-li-inner-active{
    /* color:green;*/
    text-decoration: none; 

    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.5rem 0;
    padding-left: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    background: var(--background-color-10);
    color:var(--color-7);
    
}
.icons{
    color:var(--color);
    margin-right: 0.5rem;
}
.icons-active{
    color:var(--color-7);
    margin-right: 0.5rem;
}
.logout{
    display: flex;
    text-align: left;
    color: var(--warning-text-2);
}
.logout-icon{
    float: left;
    /* margin: 0 0.5rem; */
    margin-right: 0.5rem;
}
.log-out-btn{
    font-size: 1rem;
}
.mainlogo-link{
    text-decoration: none;
    font-weight: 900;
}
.mainlogo{
    margin-left: 1rem;;
    font-size: var(--logo-font-size);
    text-align: center;
    color: var(--color-7);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dropdown{
    color: var(--color);
    margin-left: 45%;
}
.dropdown-2{
    color: var(--color);
    margin-left: 30%;
}

.message-btn-box{
    display : flex;
    align-items : center;
    /* padding:0.9rem 0; */
   
    text-decoration: none; 
    font-size: 1.1rem;
    font-weight: 400;
    /* padding: 0; */
    padding-left: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    /* background: var(--background-color); */
    color:var(--color);
}

.message-options-box{
    margin-left: 5%;
    width: 80%; 
    height: auto;
    background: var(--background-color-10);
    position : absolute; 
    color: var(--color-7); 
    padding: 1rem 0.3rem; 
    box-sizing: border-box;
    border-radius: var(--section-border-radius );
     box-shadow:
    0px 3.4px 2.7px rgba(0, 0, 0, 0.022),
    0px 8.7px 6.9px rgba(0, 0, 0, 0.031),
    0px 17.7px 14.2px rgba(0, 0, 0, 0.039),
    0px 36.5px 29.2px rgba(0, 0, 0, 0.048),
    0px 100px 80px rgba(0, 0, 0, 0.07);
    z-index: 3;
}

.message-options-box-2{
    margin-left: 0%;
    width: 80%; 
    height: auto;
    background: var(--background-color-10);
    position : absolute; 
    color: var(--color-7); 
    padding: 1rem 0.9rem; 
    box-sizing: border-box;
    border-radius: var(--section-border-radius );
     box-shadow:
    0px 3.4px 2.7px rgba(0, 0, 0, 0.022),
    0px 8.7px 6.9px rgba(0, 0, 0, 0.031),
    0px 17.7px 14.2px rgba(0, 0, 0, 0.039),
    0px 36.5px 29.2px rgba(0, 0, 0, 0.048),
    0px 100px 80px rgba(0, 0, 0, 0.07);
    z-index: 2;
    max-height: 13rem;
    overflow-x: auto;
}
.sideTop-inner-2{
    width: 100%;
    padding: var(--section-padding-2);
    box-sizing: border-box;
}

.link-2{
    margin: 0 0.4rem;
   color: var(--color);
   text-decoration: none;
   font-size: 0.95rem;
   text-transform: capitalize;
  
}
.link-2-active{
   margin: 0 0.4rem;
   color: var(--color-7);
   text-decoration: none;
   font-size: 0.95rem;
   text-transform: capitalize;
  
}
.sidebar-profile-img{
    width: 3rem;
    height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 2rem;
    border : 0.3rem solid var(--background-color-3)
}
.username1{
    text-transform: capitalize;
    font-size: 1.2rem;
}

.message-sidebar-notification{
    color: var(--warning-text-2);
    font-size : 0.9rem;
    position: absolute;
    right: 4rem;
}

.message-sidebar-notification-2{
    color: var(--warning-text-2);
    font-size : 0.9rem;
    margin: 0 0.4rem;
}

.notification-sidebar-box{
    padding: var(--section-padding);
    max-height: 15rem;
    overflow-x: auto;
    background-color: red;
}

@media screen and (max-width : 940px){
    .sidebarContainer{
        width: 70vw;
    }
    .sidebarContainer2{
        display: block;
    }
}