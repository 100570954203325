.backdrop1{
    width: 100%; 
    height: 100vh;
    position: fixed;
    top:0;
    left : 0;
    z-index: 11;
    transition: all 0.7s;
    background: rgb(53, 56, 54);
    opacity: 0.7;
}
.backdrop2{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left : 0;
    z-index: 11;
    transition: all 0.7s;
    background: rgb(53, 56, 54);
    opacity: 0.7;
    display: none;
}
@media screen and (max-width : 940px){
    .sidebarContainer{
        width: 70vw;
    }
    .sidebarContainer2{
        display: block;
    }
}