.otherUsers{
    display: flex;
    overflow-y: auto;
    background-color: var(--preview-background);
}

.other-users-inner{
    min-width: 6rem;
    height: 10rem;
    margin : 0.3rem;
    background: var(--background-color-9);
    display: block;
    text-align: center;
    position: relative;
    font-size: 0.9rem;
    color: var(--color2);
    border-radius: 0.3rem;
    padding: 0.25rem;
    box-sizing: border-box;
}

.follow-btn{
    width: 90%;
    cursor: pointer;
    color: var(--color-7);
    padding: var(--btn-padding);
    background: var( --background-color-2);
    font-size: var(--button-font-size-2);
    position: absolute;
    right: 0.3rem;
    bottom:0.2rem;
    border: 1px solid var(--background-color-2);
    border-radius: 0 0 0.2rem 0.2rem;
    
}

.others-img{
    width: 100%;
    height: 100%;
    /* margin:0.3rem; */
    object-fit: cover;
}
.others-name{
    /* position: absolute;
    color:var(--color3);
    bottom: 1.2rem;
    width: 100%;
    height: 3rem;
    opacity: 0.8;
    display: flex;
    align-items: flex-end;
    overflow-x: hidden;
    background : var(--background-color-4);
    z-index: 2; */

    text-transform: capitalize;
    padding: 0 0.3rem;
    position: absolute;
    color:var(--color3);
    top: 6.5rem;
    width: auto;
    opacity: 0.8;
    z-index: 2;
    width: 5.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.4rem 0;
    font-weight: 600;
    background: linear-gradient(to top, var(--color2), transparent);
}

.other-users-box{
    width: 100%;
    max-height: 12rem;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex; 
    grid-template-columns: repeat(auto-fit, minmax(9rem, auto));
}

.more-btn{
    padding: 0;
    border : none;
    font-weight: var(--btn-font-weight);
    font-size: var(--connections-fontsize);
    border-radius: var(--btn-border-radius);
    transition: all 0.4s;
    cursor: pointer;
    float: right;
    z-index: 2;
    background:  var(--background-color-9);
    color : var(--color5)
 
}
.more-btn:hover{
    /* background: var(--background-color-2);
    color : var(--color-7); */
    box-shadow:
    0.6px 2.8px 2.2px rgba(0, 0, 0, 0.056),
    /* 1.5px 6.7px 5.3px rgba(0, 0, 0, 0.081), */
    2.8px 12.5px 10px rgba(0, 0, 0, 0.1),
    /* 4.9px 22.3px 17.9px rgba(0, 0, 0, 0.119), */
    /* 9.2px 41.8px 33.4px rgba(0, 0, 0, 0.144), */
    0px 0px 0px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 600px) {
    .others-img{
        width: 100%;
        max-width: 6rem;
    }
    .others-name{
        width: 90%;
        max-width: 5rem;
    }
}

