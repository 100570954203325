.posts{
    width:100%;
    position: relative
}
.postContainer{
    background: var(--background-color-2);
    min-height: 5rem;
    width: auto;
    position: relative;
    padding: var(--input-padding);
    margin : 0.5rem 0;
    box-shadow:
    0px 14.3px 1.2px -7px rgba(0, 0, 0, 0.009),
    0px 43px 51px -7px rgba(0, 0, 0, 0.07);
}
.name{
    margin-left: 1rem;
    font-size: 1.2rem;
}

.post-top{
    display: flex;
}
.comment-pic{
    float: left;
    margin-right:.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border : 0.2rem solid var(--color);
    object-fit: cover;
}
.description{
    min-height: 3rem;
    padding : var(--section-padding);
    font-size: 0.9rem;
    color: var(--color2);
}
.more-btn{
    padding: var(--btn-padding);
    border : none;
    /* border: 1px solid var(--color); */
    border-radius: var(--btn-border-radius);
    background: var(--background-color);
    transition: all 0.4s;
    cursor: pointer;
}
.more-btn:hover{
    background: var(--background-color-2);
}
.icons-box{
    display: flex;
    width: 100%;
}
.icons-box-item{
    display: flex;
    flex: 6
    /* margin: 0;
    margin-right: var(--icon-li-padding); */
}
.icons-box-item2{
    text-align: right;
    margin-left: auto;
    flex: 6
    /* color:red;
    background-color: green; */
}
.icon-thumbsup{
    margin : 0 0.3rem;
    color: var(--color2);
    cursor: pointer;
    transition: all 0.4s;
    flex: 6
}
.icon-thumbsup-liked{
    margin : 0 0.3rem;
    color:#33f;
    cursor: pointer;
    transition: all 0.4s;
}
.icon-thumbsup-liked-alt{
    margin : 0 0.3rem;
    color : var(--background-color-2);
    background : #33f;
    padding: 0.3rem;
    font-size: 0.7rem;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.4s;
}
.icon-thumbsup:hover{
    transform: scale(1.2);
}

.icon-comment{
    margin : 0 0.3rem;
    color: var(--color2)
}

.comment-box-open{
    display: block;
    width : 100%;
    background-color: var(--background-color);
    transition: all 0.4s;
    
}
.comment-box-close{
    display: none;
}
.comment-input{
    box-sizing: border-box;
    width: 100%;
    padding: var(--input-padding2);
    border: 1px solid var(--color);
    position: relative;
}
.comment-input:focus{
    outline: none;
}
.users-comments{
    padding: var(--section-padding);
}
.comment-main{
    position: relative;
    margin : 1rem 0;
    padding: var(--section-padding-2);
    background: var(--background-color-2);
}
.comment{
    font-size: 1.05rem;
    color : var(--color2)
}
.comment-username{
    font-weight: 600;
    font-size: 0.9rem;
}
.comment-timeago{
    font-size: 0.8rem;
}

.submit-icon{
    color: var(--color-success);
    font-size: var(--icon-size2);
}

.more-options{
    float : right
}

.options-box{
    position: absolute;
    top: 2rem;
    right: 0rem;
    padding : var(--section-padding);
    background: var(--background-color-2);
    box-shadow:
    11.9px 20.1px 12.5px -12px rgba(0, 0, 0, 0.1),
    19px 32px 69px -12px rgba(0, 0, 0, 0.071)
  ;
    z-index: 8
    

}
.close-options{
    font-size: var(--icon-size2);
    color: var(--color-close);
    float: right;
}
.delete-box{
    background: var(--background-color);
    padding: var(--section-padding);
}
.backdrop{
    display: grid;
    place-items: center;
    width: 100%; 
    height: 100vh;
    position: fixed;
    top:0;
    left : 0;
    z-index: 11;
    transition: all 0.7s;
    background: rgba(53, 56, 54, 0.7);

}

.comment-icons{
    float: right
}

.update-icon{
    color : var(--color2);
}
.trash-icon{
    color: var(--color2);

}

.icon-btn{
    background: inherit;
    border: none;
    margin : var(--btn-margin);
    width: 1.2rem
}